import React, { useEffect } from 'react';
import { func, oneOf, shape, string } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { Camera } from '@one-thd/sui-icons';
import * as analytics from '../analytics/analytics';

const ARContent = ({ assetLink, forwardedRef, onClick }) => {

  const handleOnClick = (clickEvent) => {
    analytics.track(clickEvent);
    if (onClick) {
      if (clickEvent) {
        clickEvent.preventDefault();
      }
      onClick(assetLink);
    }
  };

  const handleMediaGalleryClick = ({ output }) => {
    const {
      component,
      target,
    } = output;

    if (target === 'ar' && component === 'thumbnails') {
      handleOnClick();
    }
  };
  useEffect(() => {

    window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.click', handleMediaGalleryClick);
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('media-gallery.click', handleMediaGalleryClick);
    };
  }, []);

  return (
    <div>
      {onClick
        ? (
          <Button
            variant="secondary"
            startIcon={Camera}
            ref={forwardedRef}
            onClick={handleOnClick}
          >
            See It in Your Home
          </Button>
        )
        : (
          <Button
            variant="secondary"
            startIcon={Camera}
            href={assetLink}
            onClick={handleOnClick}
          >
            See It in Your Home
          </Button>
        )}
    </div>
  );
};

ARContent.propTypes = {
  assetLink: string,
  forwardedRef: shape({}),
  onClick: func
};

ARContent.defaultProps = {
  assetLink: '',
  forwardedRef: null,
  onClick: null
};

export { ARContent };
