import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import {
  arrayOf, bool, element, elementType, func, node, oneOfType, string
} from 'prop-types';

class Link extends React.Component {
  linkRef = React.createRef();

  // double click to avoid ensighten :eyeroll:
  onLinkClick = () => {
    if (this.linkRef.current) {
      const { onClick } = this.props;
      this.linkRef.current.click();
      if (onClick) {
        onClick();
      }
    }
  };

  render() {
    const {
      children, href, usdz, disabled,
    } = this.props;
    if (!(href || usdz)) {
      return null;
    }
    const assetLink = href || `https://assets.homedepot-static.com/mobile-apps/3d/usdz/${usdz}`;

    return (
      <Button
        variant="primary"
        onTouchStart={this.onLinkClick}
        ref={this.linkRef}
        href={assetLink}
        disabled={disabled}
        rel="ar"
      >
        {/* The empty image is required to display the AR preview */}
        {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
        <img src="" alt="" aria-hidden="true" />
        {children}
      </Button>
    );
  }
}

Link.propTypes = {
  // Render whatever you want as the link
  children: oneOfType([element, elementType, node, arrayOf(node), string]).isRequired,
  // href to asset can be provided
  href: string,
  // Callback for when the link is clicked
  onClick: func,
  // usdz name can be provided and component will create the path
  usdz: string,
  disabled: bool
};

Link.defaultProps = {
  href: null,
  onClick: null,
  usdz: null,
  disabled: false,
};

export { Link };
