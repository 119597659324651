import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import {
  Typography,
  Skeleton,
  SkeletonLine,
  SkeletonBlock,
  DrawerHeader,
  DrawerFooter,
  DrawerBody
} from '@one-thd/sui-atomic-components';
// eslint-disable-next-line @harmony/stencil/no-component-imports
import { Link } from '../Link/Link.component';

export const ARInstructions = ({ loadedAsset, onViewerClick }) => {

  const [goingToView, setGoingToView] = useState(false);
  useEffect(() => {
    if (goingToView) {
      onViewerClick();
      setGoingToView(false);
    }
  }, [goingToView]);

  return (
    !loadedAsset
      ? (
        <div role="progressbar" aria-label="Loading...">
          <Skeleton className="sui-w-full">
            <SkeletonBlock />
            <SkeletonLine numberOfLines={3} />
          </Skeleton>
        </div>
      )
      : (
        <>
          <DrawerHeader title="See It in Your Space is Ready" onClose={() => onViewerClick()} />
          <DrawerBody>
            <Typography variant="body-base">We&apos;ll place the item in your space using your camera.</Typography>
            <Typography variant="body-base">To get the item to appear, point your phone towards the floor
              and move it around slowly.
              Avoid moving past furniture or other items that could
              prevent the viewer from finding an empty spot.
            </Typography>
            <Typography variant="body-base">Once you see the item, tap and drag it to position it in your space.
              Pinch to make it larger or smaller.
            </Typography>
          </DrawerBody>
          <DrawerFooter>
            <Link
              href={loadedAsset}
              disabled={goingToView}
              onClick={() => setGoingToView(true)}
            >
              Go to Viewer
            </Link>
          </DrawerFooter>
        </>
      )
  );
};

ARInstructions.propTypes = {
  loadedAsset: string,
  onViewerClick: func.isRequired
};

ARInstructions.defaultProps = {
  loadedAsset: null
};
