import React from 'react';
import { string } from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import { Button } from '@one-thd/sui-atomic-components';
import { AR } from '@one-thd/sui-icons';
import { avataarAugmentedRealityIdsTnT } from './avataar-skus';

export const Avataar = ({ itemId }) => {
  const fsAvataar = useConfigService('fs:isAvataarEnable');
  const containAvataarId = (id) => {
    if (id) {
      return avataarAugmentedRealityIdsTnT.indexOf(id) > -1;
    }
    return false;
  };

  const showAvataarAR = () => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('media-gallery.avataar.click', { showAvataar: true });
    }
  };

  if (!fsAvataar || !itemId || !containAvataarId(itemId)) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      startIcon={AR}
      onClick={showAvataarAR}
    >
      View in 3D
    </Button>
  );
};

Avataar.displayName = 'Avataar';

Avataar.propTypes = {
  itemId: string.isRequired,
};
