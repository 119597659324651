export const avataarAugmentedRealityIdsTnT = [
  // fusion-hdh-pip-desktop
  // fusion-hdh-pip-mobile
  '203008386', '203008658', '203184596', '206097910', '206806288', '206806292', '206806293', '303566482', '304580723',
  '304602851', '304602853', '304602855', '304602856', '304602858', '304602860', '305901526', '305976840', '305990389',
  '305993886', '307337012', '307337023', '307337039', '307864717', '308631035', '308631043', '308736997', '309122286',
  '309568118', '310031805', '310031821', '310031823', '311041653', '311041660', '311738471', '312106940', '312447366',
  '312447378', '312447400', '312899843', '312930135', '313364545', '313364546', '313703135', '313703138', '314611555',
  '314611556', '314611558', '314729408', '315100548', '315123999', '315125500', '315125680', '315396312', '315396313',
  '315396317', '315416018', '315966208', '316744779', '316744786', '316744788', '316744790', '316967939', '316967951',
  '316999600', '317000001', '317102240', '318055396', '318282777', '318304556', '318304557', '318304558', '318304559',
  '318304560', '318806668', '319445745', '319446772', '319511547', '319534774', '319535353', '319535362', '319670674',
  '319670761', '319757306', '321087975', '321898042', '321913281', '322143403', '323302719',

  // major-appliances
  '206355460', '303516039', '303516040', '312275136', '312284218', '312284270', '312285857', '312285899', '312285981',
  '312286019', '312439629', '312439700', '312440061', '312440135', '312440202', '312440282', '312440605', '312441660',
  '312450168', '312563953', '312751609', '312758394', '312903023', '313001380', '313550124', '313803342', '313846213',
  '313846237', '314024386', '314024432', '314103811', '314103818', '314145556', '314312141', '314312330', '314744262',
  '314755122', '315423162', '315423281', '315750223', '315845275', '315868024', '315868139', '315868404', '315868429',
  '315879054', '315879068', '315949991', '315972136', '316091585', '316391992', '316393269', '316758363', '317436804',
  '317436954', '317436989', '317445512', '317445728', '317450098', '317612685', '317651326', '317651351', '317651467',
  '317821963', '317842096', '317843042', '317914032', '317914041', '317989076', '317989080', '318069436', '318100023',
  '318100083', '318268534', '318268649', '319502657', '319503087', '319503107', '319615744', '319615861', '319615868',
  '319615947', '319615951', '319696043', '319857128', '319857211', '319923566', '319923732', '320020843', '320026646',
  '320061862', '320119258', '320119266', '320119641', '320326726', '320326746', '320326840', '320326878', '320326920',
  '320484893', '320587744', '320612278', '320690384', '321151188', '321253238', '321774705', '321774731', '321887244',
  '322117741',

  // fusion-gm-pip-desktop
  // fusion-gm-pip-mobile
  '313489925', '317013047', '317013048',
];
